export default class Offcanvas {
   /**
    * 
    * @param {Object} 
    * @property {string} selector - ID модального окна
    * @property {string} close - Селектор элементов закрытия
    * @property {string} open - Селектор элементов открытия
    * @property {string} overlay - Селектор overlay
    * @property {boolean} hasVideo - Налачие видео в offcanvas
    */
   constructor ({ selector, close, open = null, overlay, hasVideo = false, hasForm = false }) {
      this.$selector = document.querySelector(selector);
      this.$close = document.querySelectorAll(close);
      this.$open = document.querySelectorAll(open) || null;
      this.$overlay = document.querySelector(overlay);
      this.hasVideo = hasVideo;
      this.hasForm = hasForm;

      this.isOpen = false; // Флаг, чтобы отслеживать, открыто ли окно

      document.documentElement.addEventListener('keydown', this.handleEscape.bind(this));
      this.setup();
   }

   setup() {
      this.$open && this.eventHandler('click', this.$open, this.open.bind(this));
      this.$overlay && this.eventHandler('click', this.$overlay, this.close.bind(this));
      this.$close && this.eventHandler('click', this.$close, this.close.bind(this)); 
   }

   open() {
      this.isOpen = true
      this.handler('open', 'active', this.$selector);
      this.handler('open', 'active', this.$overlay);
      this.handler('open', 'no-scroll', document.documentElement);
   }

   async close() {
      this.isOpen = false
      this.handler('close', 'active', this.$selector);
      this.handler('close', 'active', this.$overlay);
      this.handler('close', 'no-scroll', document.documentElement);

      if (this.hasVideo) {
         const frame = this.$selector.querySelector('iframe');
         if (frame) frame.src = '';
      }

      if (this.hasForm) {
         try {
            const clearFormFields = await import('@partials/UI/form/modules/clearFormFields.js');
            let form = document.querySelector(`#${this.$selector.id} .form`)
            
            clearFormFields.default(form)
         } catch (error) {
            console.error('Ошибка при заугрузке clearFormFields (Offcanvas):', error);
         }
      }
   }

   handler(action, className, elements) {
      if (elements) {
         elements.classList.toggle(className, action === 'open');
      }
   }

   handleEscape(e) {
      if (e.key === 'Escape' && this.isOpen) {
         this.close();
      }
   }

   eventHandler(event, elements, callback) {
      // Проверяем, что переданы элементы и есть что обрабатывать
      if (!elements) return;

      // Проверяем, поддерживает ли объект forEach (NodeList или массив)
      if (elements.forEach) {
         elements.forEach((el) => el?.addEventListener && el.addEventListener(event, callback));
      } else if (elements.addEventListener) {
         elements.addEventListener(event, callback);
      }
   }
}