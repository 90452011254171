export default class Notification {
    constructor ({ selector, close, overlay, content}) {
        this.$selector = document.querySelector(selector);
        this.content = content;
        this.$close =  document.querySelectorAll(close);
        this.$overlay = document.querySelector(overlay);

        this.$contentContainer = this.$selector && this.$selector.querySelector('.content')
        this.isOpen = false; // Флаг, чтобы отслеживать, открыто ли окно
    
        document.body.addEventListener('keydown', this.handleEscape.bind(this));
        this.setup();
    }
 
    setup() {
        this.$overlay && this.eventHandler('click', this.$overlay, this.close.bind(this));
        this.$close && this.eventHandler('click', this.$close, this.close.bind(this)); 
    }
 
    open() {
        this.isOpen = true
        this.handler('open', 'active', this.$selector);
        this.handler('open', 'active', this.$overlay);
        this.handler('open', 'no-scroll', document.body);
            
        this.$contentContainer.insertAdjacentHTML('afterbegin', this.content)
    }
 
    close() {
        this.isOpen = false
        this.handler('close', 'active', this.$selector);
        this.handler('close', 'active', this.$overlay);
        this.handler('close', 'no-scroll', document.body);

        this.$contentContainer.innerText = ''
    }
 
    handler(action, className, elements) {
        if (elements) {
            elements.classList.toggle(className, action === 'open');
        }
    }
 
    handleEscape(e) {
        if (e.key === 'Escape' && this.isOpen) {
            this.close();
        }
    }
 
    eventHandler(event, elements, callback) {
        // Проверяем, что переданы элементы и есть что обрабатывать
        if (!elements) return;
    
        // Проверяем, поддерживает ли объект forEach (NodeList или массив)
        if (elements.forEach) {
            elements.forEach((el) => el?.addEventListener && el.addEventListener(event, callback));
        } else if (elements.addEventListener) {
            elements.addEventListener(event, callback);
        }
    }
 }