import Offcanvas from '@partials/UI/offcanvas/modules/Offcanvas';
import '@partials/UI/form/modules/setFormTitleAndSubject';

const getInTouch = new Offcanvas({
    selector: '#get_in_touch',
    close: '#get_in_touch .close',
    open: '.o_form',
    overlay: '#get_in_touch .overlay',
    hasForm: true
})

export {
    getInTouch
}