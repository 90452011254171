import Tab from '@partials/UI/expandable/modules/Tab';
import Accordion from '@partials/UI/expandable/modules/Accordion';

let activityAccordion = null;
let activityTabs = null;
let currentIsMobile = null; // Начальное состояние не определено

const setupExpand = function () {
    const isMobile = window.innerWidth < 900;

    // Проверка на изменение состояния
    if (currentIsMobile === isMobile) {
        return; // Если состояние не изменилось, выходим
    }

    // Обновляем состояние
    currentIsMobile = isMobile;

    // Уничтожаем предыдущие экземпляры
    if (activityAccordion) {
        activityAccordion.destroy();
        activityAccordion = null;
    }

    if (activityTabs) {
        activityTabs.destroy();
        activityTabs = null;
    }

    // Создаем новый экземпляр
    if (isMobile) {
        activityAccordion = new Accordion({
            visible: '.expandable__visible',
            collapse: '.expandable__collapse',
            onlyOneOpened: true
        });
    } else {
        activityTabs = new Tab({
            visible: '.expandable__visible',
            collapse: '.activity__content',
        });
        $('.desctop_screen').show()
    }
};

// Первичная инициализация
setupExpand();

// Обработчик изменения размера окна
window.addEventListener('resize', setupExpand);
