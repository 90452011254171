// ЗАМЕНА ЗАГОЛОВКОВ И В OFFCANVAS С ФОРМАМИ И ДОБАВЛЕНИЕ ТЕМЫ В СООБЩЕНИИ В TELEGRAM В ЗАВИСИМОСТИ ОТ ТИПА ФОРМЫ

const btnOffcanvas = document.querySelectorAll('button[data-subject]')
const offcanvas = document.getElementById('get_in_touch')

btnOffcanvas.forEach(item => {
    item.addEventListener('click', function () {
        let formSubject = offcanvas.querySelector('[type="hidden"]')

        let subject = this.dataset.subject;
        subject ? formSubject.value = subject : formSubject.value = 'Связаться с нами';
    })
})