import sendForm from '@partials/UI/form/modules/sendForm/sendForm';

const forms = document.querySelectorAll('.form')
let isSubmitting = false;

// setFormTitleAndSubject({ btn: item })

// ОТПРАВКА ФОРМЫ
function onSubmitForm(hasRecaptcha, form) {
    if (isSubmitting) return; // Блокируем отправку, если запрос уже в процессе
    isSubmitting = true; // Устанавливаем флаг, что запрос обрабатывается

    // Обрабатываем катчу, если она есть
    if (hasRecaptcha) {
        grecaptcha.ready(function () {
            // Секретный ключ reCAPTCHA
            grecaptcha.execute('6LfMUxMqAAAAAMye8HqlRm3vr-lIMkuZTFRGXFgb', { action: 'submit' }).then(function (token) {
                onSubmit(form, token).finally(() => {
                    isSubmitting = false; // Сбрасываем флаг после завершения
                });
            });
        });
    } else {
        onSubmit(form).finally(() => {
            isSubmitting = false; // Сбрасываем флаг после завершения
        });
    }
}


// ПЕРЕДАЕМ TOKEN RECAPTCHA В sendFrom(). onSubmit(token) - функция google
async function onSubmit(form, token) {
    await sendForm(form, token);
}

// СОБЫТИЕ ОТПРАВКИ ФОРМЫ
forms.forEach(form => {
    form.addEventListener('submit', function (e) {
        e.preventDefault();
        onSubmitForm(false, form)
    });
})