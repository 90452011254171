import validateForm from './validateForm'
import { getInTouch } from '@partials/get-in-touch/get-in-touch';
import { successForm, unsuccessForm, createInstanceNotification } from '@partials/UI/notifications/notifications'
import clearFormFields from '@partials/UI/form/modules/clearFormFields';

export default async function sendForm(form, token = null) {
   const PATH_TELEGRAM_PHP = '../libs/telegram.php';

   try {
      const isValid = validateForm(form);

      if (isValid) {
         const formData = new FormData(form);

         token && formData.append('g-recaptcha-response', token);

         const response = await fetch(PATH_TELEGRAM_PHP, {
            method: "POST",
            body: formData,
         });

         if (response.ok) {
            getInTouch.$selector && getInTouch.close()
            clearFormFields(form)
            createInstanceNotification(successForm).open()
         } else {
            clearFormFields(form)
            createInstanceNotification(unsuccessForm).open();
         }
      }
   } catch (error) {
      console.log('Форма не отправилась (catch)');
   }
}

