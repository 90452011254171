export default function Accordion({ visible, collapse, onlyOneOpened = false }) {
    $(collapse).hide()

    $(visible).on('click', function () {
        let self = $(this)

        if (!onlyOneOpened) {
            self.toggleClass('active').next().slideToggle()
        } else {
            $(visible).not(self).removeClass('active').next().slideUp()
            self.toggleClass('active').next().slideToggle()
        }
    })

    this.destroy = function () {
        $(visible).off('click').removeClass('active');
        $(collapse).show();
    };
}