const scrollUpNodes = document.querySelectorAll('.scroll_up')

scrollUpNodes.forEach(item => {
    item.addEventListener('click', () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    })
})